<template>
  <div>
    <CCard>
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="No usulan"
                >
                  <CInput
                    label="No Usulan"
                    placeholder="No Usulan"
                    v-model="form.no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  />
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Tanggal Usulan"
                >
                  <div role="group" class="form-group">
                    <label class>Tanggal Usulan</label>
                    <datepicker
                      placeholder="Tanggal Usulan"
                      v-model="form.tgl_surat"
                      :bootstrap-styling="true"
                      :input-class="'datePicker'"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></datepicker>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="3">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Referensi No. Surat"
                >
                  <CInput
                    label="Referensi No. Surat"
                    placeholder="Referensi No. Surat"
                    v-model="form.referensi_no_surat"
                    :invalid-feedback="errors[0]"
                    :isValid="!errors[0]"
                  />
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Instansi"
                >
                  <div role="group" class="form-group">
                    <label class>Instansi Tujuan</label>
                    <v-select
                      v-model="form.id_instansi"
                      :options="optionsInstansi"
                      label="nama_instansi"
                      :reduce="(instansi) => instansi.id_instansi"
                      :filterable="false"
                      @search="onSearchInstansi"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <!-- <CRow>
              <CCol>
                <CCard accentColor="primary">
                  <CCardHeader>
                    <h6>Tembusan</h6>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                          <CButton
                            class="col-md-3 mb-3"
                            color="info"
                            @click="addTembusan"
                          >
                            <CIcon name="cil-plus" />Tambah Tembusan
                          </CButton>
                        </CButtonGroup>
                        <div class="position-relative table-responsive">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th
                                  class
                                  style="vertical-align: middle; overflow: hidden"
                                >
                                  <div>No</div>
                                  
                                </th>
                                <th
                                  class
                                  style="vertical-align: middle; overflow: hidden"
                                >
                                  <div>Tembusan</div>
                                  
                                </th>
                                <th
                                  class
                                  style="vertical-align: middle; overflow: hidden"
                                ></th>
                              </tr>
                              
                            </thead>
                            <tbody class="position-relative">
                              <template
                                v-for="(tembusan, index_tembusan) in form.tembusan"
                              >
                                <tr
                                  :key="index_tembusan"
                                  v-if="tembusan.is_deleted == 0"
                                >
                                  <td style="width: 10%">
                                    {{ index_tembusan + 1 }}
                                  </td>
                                  <td>
                                    <input
                                      v-model="tembusan.tembusan"
                                      type="text"
                                      placeholder
                                      class="form-control"
                                    />
                                  </td>
                                  <td style="width: 10%">
                                    <CButtonGroup size="sm">
                                      <CButton
                                        color="danger"
                                        class="px-4"
                                        @click="deleteTembusan(index_tembusan)"
                                        >Hapus</CButton
                                      >
                                    </CButtonGroup>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                            
                          </table>
                          
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow> -->
            <CRow>
              <CCol>
                <CCard accentColor="primary">
                  <CCardHeader>
                    <h6>Pegawai</h6>
                  </CCardHeader>
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <CButtonGroup size="sm" class="flex-row-reverse d-flex">
                          <CButton
                            class="col-md-3 mb-3"
                            color="info"
                            @click="addDetails"
                          >
                            <CIcon name="cil-plus" />Tambah Pegawai
                          </CButton>
                        </CButtonGroup>
                        <template v-for="(data, index) in form.details">
                          <fieldset
                            class="form-group border p-3"
                            :key="index"
                            v-if="data.is_deleted == 0"
                          >
                            <div small style :key="componentKey">
                              <CRow>
                                <CCol>
                                  <CButtonGroup
                                    size="sm"
                                    class="flex-row-reverse d-flex"
                                  >
                                    <CButton
                                      class="col-md-1"
                                      color="danger"
                                      @click="deleteDetails(index)"
                                      >Hapus</CButton
                                    >
                                  </CButtonGroup>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol md="6">
                                  <validation-provider
                                    rules="required"
                                    v-slot="{ errors }"
                                    name="NIP - Nama"
                                  >
                                    <div role="group" class="form-group">
                                      <label class>NIP - Nama</label>
                                      <v-select
                                        :key="'select_' + componentKey + index"
                                        v-model="data.petugas_array"
                                        :options="data.optionsPgw"
                                        label="nama"
                                        :filterable="false"
                                        @search="onSearch(index, $event)"
                                        @input="onChangesPegawai(index, $event)"
                                        :class="[
                                          { 'is-valid': !errors[0] },
                                          { 'is-invalid': errors[0] },
                                        ]"
                                      ></v-select>
                                      <div
                                        class="invalid-feedback"
                                        v-if="errors[0]"
                                      >
                                        {{ errors[0] }}
                                      </div>
                                    </div>
                                  </validation-provider>
                                </CCol>
                              </CRow>

                              <fieldset
                                class="form-group border p-3"
                                v-if="data.informasi_pegawai"
                              >
                                <legend class="w-auto px-2">
                                  <h6>Informasi Pegawai</h6>
                                </legend>

                                <CRow>
                                  <CCol md="6">
                                    <CInput
                                      label="Pangkat Gol/Ruang"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai
                                          .pangkat_golongan_ruang
                                      "
                                    ></CInput>
                                    <CInput
                                      label="Jabatan"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.nama_jabatan
                                      "
                                    ></CInput>
                                    <CInput
                                      label="Jenis Kelamin"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.jenis_kelamin
                                      "
                                    ></CInput>
                                    <CInput
                                      label="Gaji Pokok"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.gaji_pokok
                                      "
                                    ></CInput>
                                  </CCol>
                                  <CCol md="6">
                                    <CInput
                                      label="TMT Pangkat"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.tmt_pangkat
                                      "
                                    ></CInput>
                                    <CInput
                                      label="Nama Unit Kerja"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.nama_unit_kerja
                                      "
                                    ></CInput>
                                    <CInput
                                      label="Umur"
                                      placeholder
                                      readonly
                                      v-model="data.informasi_pegawai.umur"
                                    ></CInput>
                                    <CInput
                                      label="Masa Jabatan"
                                      placeholder
                                      readonly
                                      v-model="
                                        data.informasi_pegawai.masa_jabatan
                                      "
                                    ></CInput>
                                  </CCol>
                                </CRow>
                              </fieldset>
                              <fieldset
                                class="form-group border p-3"
                                v-if="data.informasi_pegawai"
                              >
                                <legend class="w-auto px-2">
                                  <h6>Informasi Mutasi Pegawai</h6>
                                </legend>

                                <CRow>
                                  <CCol md="12">
                                    <validation-provider
                                      rules="required"
                                      v-slot="{ errors }"
                                      name="Jabatan"
                                    >
                                      <CInput
                                        label="Jabatan"
                                        placeholder="jabatan"
                                        v-model="data.jabatan"
                                        :invalid-feedback="errors[0]"
                                        :isValid="!errors[0]"
                                      />
                                    </validation-provider>
                                    <validation-provider
                                      rules="required"
                                      v-slot="{ errors }"
                                      name="Unit Kerja"
                                    >
                                      <CInput
                                        label="Unit Kerja"
                                        placeholder="Unit Kerja"
                                        v-model="data.unit_kerja"
                                        :invalid-feedback="errors[0]"
                                        :isValid="!errors[0]"
                                      />
                                    </validation-provider>
                                  </CCol>
                                </CRow>
                              </fieldset>
                              <div class="position-relative table-responsive">
                                <table class="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th
                                        class
                                        style="
                                          vertical-align: middle;
                                          overflow: hidden;
                                        "
                                      >
                                        <div>No</div>
                                      </th>
                                      <th
                                        class
                                        style="
                                          vertical-align: middle;
                                          overflow: hidden;
                                        "
                                      >
                                        <div>Jenis Dokumen</div>
                                      </th>
                                      <th
                                        class
                                        style="
                                          vertical-align: middle;
                                          overflow: hidden;
                                        "
                                      >
                                        <div>Format File</div>
                                      </th>
                                      <!-- <th
                                        class
                                        style="vertical-align: middle; overflow: hidden;"
                                      >
                                        <div>Status Upload</div>
                                        
                                      </th> -->
                                      <th
                                        class
                                        style="
                                          vertical-align: middle;
                                          overflow: hidden;
                                        "
                                      >
                                        <div>File Upload</div>
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody class="position-relative">
                                    <tr
                                      v-for="(dataUpload, index2) in syarat"
                                      :key="index2"
                                    >
                                      <td>{{ index2 + 1 }}</td>
                                      <td>{{ dataUpload.nama_dokumen }}</td>
                                      <td>{{ dataUpload.format_file }}</td>
                                      <!-- <td>
                                        <template v-if="!!data.upload_detail[index2]">
                                          <div
                                            v-if="data.upload_detail[index2].upload_dokumen"
                                          >
                                            Berhasil
                                          </div>
                                        </template>
                                      </td> -->
                                      <td style="width: 20%">
                                        <CInputFile
                                          label="File input"
                                          @change="
                                            uploadFile(
                                              $event,
                                              index,
                                              index2,
                                              dataUpload.id,
                                              dataUpload.nama_dokumen,
                                              dataUpload.format_file
                                            )
                                          "
                                          :isValid="true"
                                          custom
                                        />
                                        <template
                                          v-if="!!data.upload_detail[index2]"
                                        >
                                          <CSpinner
                                            color="success"
                                            size="sm"
                                            v-if="
                                              data.upload_detail[index2]
                                                .loadingFile
                                            "
                                          />
                                        </template>

                                        <CButtonGroup
                                          size="sm"
                                          v-if="!!data.upload_detail[index2]"
                                        >
                                          <CButton
                                            color="success"
                                            class="px-4"
                                            @click="downloadFile(index, index2)"
                                            v-if="
                                              data.upload_detail[index2]
                                                .upload_dokumen
                                            "
                                            >Download File</CButton
                                          >
                                          <CButton
                                            color="danger"
                                            class="px-4"
                                            @click="deleteFile(index, index2)"
                                            v-if="
                                              data.upload_detail[index2]
                                                .upload_dokumen
                                            "
                                            >Hapus File</CButton
                                          >
                                        </CButtonGroup>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </fieldset>
                        </template>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CButton color="success" class="px-4 mr-2" @click="CloseForm()">
                  Kembali</CButton
                >
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else
                  >Simpan</CButton
                >
              </CCol>
              <CCol col="6" class="text-right"> </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import VSelect from "vue-select";
import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "FormUsulanMutasiKeluar",
  components: {
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Datepicker,
  },
  watch: {
    item: function (item) {
      this.form.id = item.id;
      this.form.id_instansi = null;
      this.form.id_unit_kerja = null;
      this.form.no_surat = null;
      this.form.tgl_surat = null;
      this.form.lampiran = null;
      this.form.referensi_no_surat = null;
      this.optionsInstansi = [];
      this.optionsUnitKerja = [];
      this.form.details = [];
      if (item) {
        this.form.id_instansi = item.id_instansi.toString();
        this.form.id_unit_kerja = item.id_unit_kerja;
        this.form.no_surat = item.no_surat;
        this.form.tgl_surat = item.tgl_surat;
        const array = [];
        array.push(item.mst_instansi);
        this.optionsInstansi = array;
        const array2 = [];
        array2.push(item.mst_unit_kerja);
        this.optionsUnitKerja = array2;

        item.pelayanan_detail.forEach((element) => {
          const arrayPegawai = [];
          arrayPegawai.push({
            nip: element.d_identitas.nip,
            nama: element.d_identitas.nip + " - " + element.d_identitas.nama,
          });
          this.form.details.push({
            id: element.id,
            nip: element.nip,
            ref_jenis_layanan_id: element.ref_jenis_layanan_id,
            ref_persyaratan_pelayanan_id: element.ref_persyaratan_pelayanan_id,
            upload_file: element.upload_file,
            jenis_pelayanan_array: element.ref_jenis_pelayanan,
            petugas_array: arrayPegawai,
            optionsPgw: arrayPegawai,
            informasi_pegawai: element.d_identitas.v_detail_informasi_pegawai,
            upload_detail: element.pelayanan_detail_upload,
            is_deleted: 0,
            jabatan: element.pegawai_mutasi_keluar.jabatan,
            unit_kerja: element.pegawai_mutasi_keluar.unit_kerja,
          });
        });

        item.detail_mutasi_keluar.forEach((element) => {
          this.form.lampiran = element.lampiran;
          this.form.referensi_no_surat = element.referensi_no_surat;
        });

        const tembusanContainer = [];
        item.tembusan_pelayanan.forEach((element) => {
          tembusanContainer.push({
            id: element.id,
            is_deleted: 0,
            tembusan: element.tembusan,
          });
        });
        this.form.tembusan = tembusanContainer;
      }
    },
  },
  data() {
    return {
      errorMessage: null,
      optionsPgw: [],
      optionsInstansi: [],
      optionsUnitKerja: [],
      optionsJenisLayanan: [],
      formVisibility: false,
      form: {
        id: null,
        id_instansi: null,
        id_unit_kerja: null,
        no_usulan: null,
        tanggal_surat: null,
        referensi_no_surat: null,
        lampiran: null,
        details: [],
        tembusan: [],
      },
      loading: false,
      item: null,
      componentKey: 0,
      syarat: [],
    };
  },
  created() {},
  methods: {
    async getSyarat(nip, index) {
      const syarat = await axios.get("/api/layanan/syarat-mutasi-keluar");
      const me = this;
      if (syarat) {
        this.syarat = syarat.data;
      }
      this.syarat.forEach(async (element) => {
        let document = await me.$store.dispatch("d_identitas/document", {
          nip: nip,
          id: element.id,
        });
        console.log(element)
        const upload = this.form.details[index]["upload_detail"];
        upload.push({
          loadingFile: false,
          upload_dokumen: document.file_path,
          nama_dokumen: element.nama_dokumen,
          ref_persyaratan_pelayanan_id: element.id,
          format_file: element.format_file,
        });
      });
    },
    populateInput(item) {
      this.searchLayanan("", this);
      this.item = item;
    },
    async searchLayanan(search) {
      let options = await this.$store.dispatch(
        "jenis_layanan/autocomplete",
        search
      );
      this.optionsJenisLayanan = options;
    },
    addDetails() {
      this.form.details.push({
        id: null,
        nip: null,
        ref_jenis_layanan_id: null,
        ref_persyaratan_pelayanan_id: null,
        upload_file: null,
        jenis_pelayanan_array: [],
        petugas_array: [],
        optionsPgw: [],
        informasi_pegawai: null,
        upload_detail: [],
        is_deleted: 0,
        jabatan: null,
        unit_kerja: null,
      });
    },
    deleteDetails(index) {
      this.form.details[index]["is_deleted"] = 1;
    },
    addTembusan() {
      this.form.tembusan.push({
        id: 0,
        tembusan: null,
        is_deleted: 0,
      });
    },
    deleteTembusan(index) {
      this.form.tembusan[index]["is_deleted"] = 1;
    },
    async uploadFile(files, index, index2, id, nama_dokumen, format_file) {
      const file = new FormData();
      file.append("files", files[0]);
      file.append("type", "mutasi_keluar");
      file.append("path", "dokumen-pegawai");
      file.append(
        "name_file",
        this.form.details[index]["upload_detail"][index2]["nama_dokumen"] +
          "_" +
          this.form.details[index]["nip"]
      );
      try {
        this.form.details[index]["upload_detail"][index2] = {
          loadingFile: true,
        };
        this.componentKey += 1;
        let { data } = await axios.post("/api/upload-document", file);
        this.form.details[index]["upload_detail"][index2] = {
          loadingFile: false,
          upload_dokumen: data.url,
          nama_dokumen: nama_dokumen,
          ref_persyaratan_pelayanan_id: id,
          format_file: format_file,
        };
      } catch (error) {
        console.log(error);
        this.form.details[index]["upload_detail"].push({
          loadingFile: false,
          upload_dokumen: null,
        });
      } finally {
        this.componentKey += 1;
      }
    },
    downloadFile(index, index2) {
      window.open(
        this.form.details[index]["upload_detail"][index2]["upload_dokumen"],
        "_blank"
      );
    },
    deleteFile(index, index2) {
      this.form.details[index]["upload_detail"][index2]["upload_dokumen"] =
        null;
    },
    async onSearchInstansi(search, loading) {
      loading(true);
      await this.searchInstansi(search, this);
      loading(false);
    },
    searchInstansi: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "master_instansi/autocomplete",
        search
      );
      if (search) vm.optionsInstansi = options;
    }, 300),
    async onSearch(index, search) {
      // loading(true);
      await this.search(index, search, this);
      // loading(false);
    },
    search: _.debounce(async (index, search, vm) => {
      let options = await vm.$store.dispatch(
        "d_identitas/autocomplete",
        search
      );
      if (search) {
        requestAnimationFrame(() => {
          vm.form.details[index]["optionsPgw"] = options;
        });
      }
    }, 300),
    onChangesPegawai(index, value) {
      this.form.details[index].informasi_pegawai =
        value.v_detail_informasi_pegawai;
      this.form.details[index].nip = value.nip;

      this.getSyarat(value.nip, index);
      this.componentKey += 1;
    },
    async onSearchUnitKerja(search, loading) {
      loading(true);
      await this.searchUnitKerja(search, this);
      loading(false);
    },
    searchUnitKerja: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch("master_unit_kerja/autocomplete", {
        search: search,
        id_instansi: vm.form.id_instansi,
        parent: "",
      });
      if (search) vm.optionsUnitKerja = options;
    }, 300),
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        var form = { ...this.form };
        form.details.forEach((value) => {
          value.petugas_array = null;
          value.optionsPgw = null;
          value.informasi_pegawai = null;
        });
        let { status } = this.item
          ? await this.$store.dispatch("usulan_mutasi_keluar/update", {
              data: form,
              id: vm.item.id,
            })
          : await this.$store.dispatch("usulan_mutasi_keluar/store", form);
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            requestAnimationFrame(() => {
              this.$refs.observer.reset();
              this.resetForm();
            });
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
    CloseForm() {
      this.$emit("clicked", !this.formVisibility);
      this.resetForm();
    },
    resetForm() {
      this.form.id = null;
      this.form.no_surat = null;
      this.form.tgl_surat = null;
      this.form.id_instansi = null;
      this.form.id_unit_kerja = null;
      this.form.no_usulan = null;
      this.form.referensi_no_surat = null;
      this.form.lampiran = null;
      this.form.tanggal_surat = null;
      this.form.details = [];
      this.form.tembusan = [];
    },
  },
};
</script>
